import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const get = async (endpoint, token="") => {
  try {
    const response = await axios.get(API_URL + endpoint);
    return { code: response.status, data: response.data };
  } catch (error) {
    // Handle error
    console.log(error)
    // console.error('Error:', error.response.data);
    return { code: error.response.status, data: error.response.data };
  }
}
export { get };