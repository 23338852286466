import { useEffect } from "react";
import parse from "html-react-parser"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as brands_svg_icon from "@fortawesome/free-brands-svg-icons";
export default ({data,contact})=>{
  useEffect(()=>{
    window.AOS.init({
        offset:0
    });
  },[])
    return (
    <div className='row p-4 align-items-center ' data-aos="fade-left" id="about-me">
        <div className='col-12'>
          <div className="border border-1 border-dark mb-4" data-aos="fade-left" data-aos-duration="1000">
            {/* Top border content */}
          </div>
          <div className='fs-1 w-100 text-center'>About Me</div>
          <div className='w-100 '>{parse(data.desc?data.desc:"")}</div>
          <div className="mt-4 row">
            {
              contact.map(item=>(
                <a href={item.link} className="m-2 border border-1 rounded-3 text-black p-2 text-center" target="_blank" style={{width:'auto'}}>
                  <FontAwesomeIcon icon={brands_svg_icon[item.icon]} size="2x"/>
                </a>
              ))
            }
          </div>
          <div className="mt-4">
            <a href={data._cv} target="_blank">
              <input type="button" className="btn btn-primary" value="Download CV"/>
            </a>
          </div>
          <div className="border border-1 border-dark mt-4" data-aos="fade-right" data-aos-duration="1000">
            {/* Top border content */}
          </div>
        </div>
    </div>
    )
}