import { useEffect, useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
const MenubarItem=({label,setShowMenu,to})=>{
    return (
        <a style={{textDecoration:'none',color:'inherit'}} className="menubar-item cursor-pointer" href={to} onClick={()=>{setShowMenu(false)}}>{label}</a>
    )
}
export default ()=>{
    const [stick,setStick]=useState(false);
    const [showMenu,setShowMenu]=useState(false);
    useEffect(()=>{
        window.addEventListener('scroll', ()=>{
            let scrollNow=window.scrollY;
            setStick(scrollNow>=120);
        });
    },[])
    return (
        <div style={{top:'0px',zIndex:'3',boxShadow:'0px 1px 10px 1px black'}} className={stick?"position-sticky bg-white text-black d-block d-lg-flex justify-content-lg-between":"position-relative bg-dark text-light d-block d-lg-flex justify-content-lg-between"} >
        <div className="d-flex justify-content-lg-center justify-content-between p-2 align-items-center">
            <div>Ratzone</div>
            <div className="d-block d-lg-none cursor-pointer" onClick={()=>setShowMenu(n=>!n)}>
                <FontAwesomeIcon size="2x" icon={faBars}/>
            </div>
        </div>
        <div className={showMenu?'d-block active menu-item d-lg-flex justify-content-between':'d-block menu-item d-lg-flex justify-content-between'}>
            <MenubarItem label="About Me" setShowMenu={setShowMenu} to="#about-me"/>
            <MenubarItem label="Education Background" setShowMenu={setShowMenu} to="#education-background"/>
            <MenubarItem label="Working Experience" setShowMenu={setShowMenu} to="#working-experience"/>
            <MenubarItem label="Portfolio" setShowMenu={setShowMenu} to="#portfolio"/>
            <MenubarItem label="Certificate" setShowMenu={setShowMenu} to="#certificate"/>
        </div>
    </div>
    )
}