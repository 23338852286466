import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useRef,useEffect,useState} from "react"
import { Oval } from "react-loader-spinner"
import Modal from "../Globals/Modal";
import {getOnePortfolio} from "../../Services/Home"
import parse from 'html-react-parser'
const ModalDetail=({data,show,setShow})=>{

    return (
        <Modal show={show} setShow={setShow}>
            <div className="modal-header">
                <h5 className="modal-title">{data.name}</h5>
                <button type="button" className="btn btn-danger" aria-label="Close" onClick={()=>{setShow(false)}}>
                    <FontAwesomeIcon icon={faX} size="1x"/>
                </button>
            </div>
            <div className="modal-body">
                <div className="row justify-content-between">
                    <div className="col-12 col-md-3">
                        <img src={data._file} style={{width:'100%'}}/>
                        <div>made with {data.made_with}</div>
                        <div className="mt-4 d-flex justify-content-center">
                            <a href={data.link} target="_blank">
                                <input type="button" className="btn btn-primary" value="preview"/>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        {parse(data.desc)}
                        <div className="row">
                            <div className="w-100 text-center">galery</div>
                            {
                                data.galery.map((item)=>(
                                    <div className="col-5 col-md-3 m-1">
                                        <img src={item._file} style={{width:'100%'}}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary">
                    Save changes
                </button>
            </div>
        </Modal>
    )
}

const Box=({item,setShow,setData,id})=>{
    const borderTop=useRef(null);
    const borderBottom=useRef(null);
    const borderLeft=useRef(null);
    const borderRight=useRef(null);
    const [loading,setLoading]=useState(false);
    const getDetail=async()=>{
        setLoading(true);
        const data=await getOnePortfolio(id);
        setData(data.data.data);
        setLoading(false);
        setShow(true);
    }
    const showBorder=function(){
        borderTop.current.style.transform="scaleX(1)";
        borderBottom.current.style.transform="scaleX(1)";
        borderLeft.current.style.transform="scaleY(1)";
        borderRight.current.style.transform="scaleY(1)";
    }
    const hideBorder=function(){
        borderTop.current.style.transform="scaleX(0)";
        borderBottom.current.style.transform="scaleX(0)";
        borderLeft.current.style.transform="scaleY(0)";
        borderRight.current.style.transform="scaleY(0)";
    }
    return (
        <div onMouseOver={showBorder} onMouseOut={hideBorder} className="col-10 col-md-5 col-lg-3 border border-1 border-solid rounded-2 m-2 position-relative" data-aos="fade-bottom">
            <div ref={borderTop} className="position-absolute w-100 rounded-2 border border-1 border-primary" style={{top:'0px',transition:'.5s',transform:'scaleX(0)',transformOrigin:'left'}}></div>
            <div ref={borderBottom} className="position-absolute w-100 rounded-2 border border-1 border-primary" style={{bottom:'0px',transition:'.5s',transform:'scaleX(0)',transformOrigin:'right'}}></div>
            <div ref={borderLeft} className="position-absolute h-100 rounded-2 border border-1 border-primary" style={{left:'0px',transition:'.5s',transform:'scaleY(0)',transformOrigin:'bottom'}}></div>
            <div ref={borderRight} className="position-absolute h-100 rounded-2 border border-1 border-primary" style={{right:'0px',transition:'.5s',transform:'scaleY(0)',transformOrigin:'top'}}></div>
            <div className="p-2">
                <img src={item._title_img} className="rounded-2" style={{width:'100%'}}/>
                <div className="col-12 p-3">
                    <div className="row">
                        <div className="fs-2">{item.name}</div>
                        <div className="fs-6 text-secondary">{item.type}</div>
                        <div className="w-100">
                            <div className="row justify-content-around">
                                <button type="button" className="btn mb-4 mb-md-0 col-10 col-md-4 btn-primary" onClick={()=>{getDetail(item.id)}}>
                                    {
                                        loading?
                                        <div className="w-100 justify-content-center d-flex">
                                            <Oval width={10} height={10} color="black"/>
                                        </div>
                                        :
                                        <span>Detail</span>
                                    }
                                </button>
                                <input type="button" className="btn col-10 col-md-4 btn-success" value="Preview"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ({portfolioData})=>{
    const [show,setShow]=useState(false);
    const [data,setData]=useState({
        "name": "",
        "type": "",
        "desc": "",
        "link": "",
        "made_with": "",
        "title_img": "",
        "galery": [],
        "_file": ""
      })
    useEffect(()=>{
        window.AOS.init({
            offset:0
        });
      },[])
    return (
        <>
            <ModalDetail data={data} show={show} setShow={setShow}/>
            <div className='row mt-4' data-aos="fade-top" id="portfolio">
                <div className='col-12 text-center'>
                <div className="fs-1">Portfolio</div>
                <div className="text-secondary text-center fs-5">my masterpiece so far</div>
                </div>

                <div className='col-12 text-center mt-4'>
                    <div className="row justify-content-around">
                        {
                            portfolioData.map((item,index)=>(
                                <Box id={item.id} setData={setData} item={item} setShow={setShow}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>

    )
}