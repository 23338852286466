import {useEffect, useRef} from "react"
const Box=({item})=>{
  return (
      <div className="col-12 m-2 position-relative border-bottom border-1" data-aos="fade-bottom">
          <div className="fs-6">{item.from} - {item.to!=0?item.to:'now'}</div>
          <div className="fs-4">{item.name}</div>
      </div>
  )
}
export default ({data})=>{
  let elementRef=useRef(null);
  useEffect(()=>{
    const observer = new IntersectionObserver((target)=>{
      if(target[0].isIntersecting){
        target[0].target.querySelector('.border').style.transform='scaleY(1)';
      }
      else{
        target[0].target.querySelector('.border').style.transform='scaleY(0)';
      }
    });
    observer.observe(elementRef.current);
    window.AOS.init({
        offset:0
    });
  },[])
  return (
    <div className='col-12 col-lg-5 mt-4 mt-lg-0' data-aos="fade-bottom" ref={elementRef} id="working-experience">
      <div className='w-100 text-center'>
        <div className="fs-1">Working Experience</div>
        <div className="text-secondary text-center fs-5">where i've been to</div>
      </div>

      <div className='w-100 mt-4'>
          <div className="d-flex justify-content-end">
              <div className="col-10 d-flex">
                <div className="border border-1 border-primary" style={{transition:'2s',transform:'scaleY(0)',transformOrigin:'bottom'}}></div>
                <div>
                <div className="row ms-4">
                    {
                        data.map((item,index)=>(
                            <Box item={item}/>
                        ))
                    }
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}