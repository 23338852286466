import { get } from "./Fetch";
import freebel from "../assets/images/freebel98.jpg"
import polygon from "../assets/images/polygon.jpg"
import ratdev from "../assets/images/ratdev.jpg"

const data=[
    {
        img:ratdev,
        link:'https://www.youtube.com/embed/Hl3K6p4eQ_0?si=jsWZ0_ydsFoAa3UB',
        title:'DNS Spoofing',
        author:'Ratskull',
        type:'video',
        date:'Sunday 01 january 2001',
        description:'this is the short description'
    },
    {
        img:freebel,
        title:'Man in the middle attack',
        content:'<p>Incididunt exercitation eu ut eiusmod id adipisicing minim ea occaecat culpa cupidatat cupidatat. Ad excepteur occaecat voluptate cillum exercitation voluptate cillum non culpa elit eu. Nulla do pariatur adipisicing laboris officia esse in enim consectetur et id reprehenderit. Culpa adipisicing mollit in amet elit ullamco.</p><p>Elit aute proident commodo velit duis laborum elit sint elit ad. Laborum dolor duis ea nulla voluptate magna do enim. Tempor laborum irure esse irure eiusmod aute aliquip Lorem Lorem sint non. Anim esse sit culpa dolore qui fugiat deserunt eiusmod tempor dolor culpa. Proident aliqua duis elit culpa deserunt exercitation culpa aliqua fugiat.</p>',
        author:'Freebel',
        type:'blog',
        date:'Sunday 01 january 2001',
        description:'this is the short description'
    },
    {
        img:polygon,
        title:'New Spam Script Bot',
        link:'https://www.youtube.com/embed/SW-HDmAjMhg?si=xbo3Gmkh2mfig4u8',
        author:'Polygon',
        type:'video',
        date:'Sunday 01 january 2001',
        description:'this is the short description'
    }
];

const readAll=async()=>{
    return data;
}
const readOne=async(id)=>{
    return data.filter((item,index)=>index==id);
}
export {readAll,readOne}