import Dashboard from "../Pages/Dashboard/Dashboard"
import Article from "../Pages/Articles/Articles"
import Editor from "../Pages/Editor/Editor";
const route = [
  {
    'path': '/',
    'element': <Dashboard />
  },
  {
    'path': '/article/:id',
    'element': <Article />
  },
  {
    'path': '/editor',
    'element': <Editor />
  }
];
export default route;