import { useEffect,useRef } from "react";
export default ({show,setShow,children})=>{
    const modalRef=useRef(null);
    useEffect(()=>{
        if(show){
            modalRef.current.classList.add('d-block');
            setTimeout(()=>{
                modalRef.current.classList.add('show');
            },100)
        }
        else{
            modalRef.current.classList.remove('show');
            setTimeout(()=>{
                modalRef.current.classList.remove('d-block');    
            },100)
        }
    },[show])
    return (
        <div className='modal fade' ref={modalRef} style={{ background: show ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0)', transition: 'opacity 0.3s ease' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
            <div className="modal-content">
                {children}
            </div>
            </div>
        </div>
    )
}