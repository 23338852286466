import { useEffect, useState } from "react";
import MainRoute from "../Routes/Route";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import { Link, Route, Routes,useNavigate} from "react-router-dom";
const MenubarItem=({label,onClick})=>{
    return (
        <div className="menubar-item cursor-pointer" onClick={onClick}>{label}</div>
    )
}
export default()=>{
    const [stick,setStick]=useState(false);
    const [showMenu,setShowMenu]=useState(false);
    const nav=useNavigate();
    useEffect(()=>{
        window.addEventListener('scroll', ()=>{
            let scrollNow=window.scrollY;
            setStick(scrollNow>=120);
        });
    },[])
    const GoTo=(path)=>{
        nav(path);
        setShowMenu(false);
    }
    return(
    <>
    
    <Routes>
        {
            MainRoute.map((item,index)=>(
                <Route key={index} path={item.path} element={item.element}/>
            ))
        }
    </Routes>
    </>
    )
}