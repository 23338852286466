import { useEffect } from "react";
import Layout from "./Layout/Layout";
import Footer from "./Components/Globals/Footer";
function App() {
  useEffect(()=>{
    window.AOS.init({ // Initialization
      duration: 500
    });
  },[])
  return (
    <>
      <div className="main">
        <Layout/>
        
      </div>
      <Footer/>
    </>
  );
}

export default App;
