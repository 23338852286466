import '../../assets/custom.scss'
import { useEffect } from 'react';
export default ({data})=>{
  useEffect(()=>{
    window.AOS.init({
        offset:0
    });
  },[])
    return (
    <div style={{background:'url('+data._file+")",backgroundSize:'cover',backgroundPositionX:'right'}} className='row h-90-vh align-items-center' data-aos="fade-top">
        <div className='col-12 text-center' style={{background:'rgb(95,95,95,0.5)'}}>
          <div className='fs-1 w-100 text-light'>Welcome To {data.name} </div>
        </div>
    </div>
    )
}