import {get} from "./Fetch"

const getAllData=async ()=>{
    const data=await get("");
    return data;
}


const getOnePortfolio=async(id)=>{
    const data=await get(`portfolio/${id}`);
    return data;
}

export {
    getAllData,
    getOnePortfolio
}