import { useEffect,useState } from "react";
import { useParams} from "react-router-dom";
import { readOne } from "../../Services/Articles";
import { Oval } from "react-loader-spinner";
import parser from "html-react-parser"
export default ()=>{
    const {id}=useParams();
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(true);
    const read=async()=>{
        setLoading(true);
        setTimeout(async ()=>{
            const data=await readOne(id);
            console.log(data);
            setData(data);
            setLoading(false);
        },2000)
    }
    useEffect(()=>{
        window.scrollTo(0,0);
        read();
    },[id])
    return (
    <div className="w-100 p-3">
        {
            loading
            ?
            <div className="d-flex justify-content-center">
                <Oval width={30} height={30}/>
            </div>
            :
            <div className="card">
                <div className="card-header">
                    <h1>{data[0].title}</h1>
                </div>
                <div className="card-body">
                    {data[0].type=="video"
                    &&
                    <iframe style={{width:'100%',height:'70vh'}} src={data[0].link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    }
                    {data[0].type=="blog"
                    &&
                    parser(data[0].content)
                    }
                </div>
            </div>
        }
    </div>
    )
}