import {useRef,useEffect} from "react"
const Box=({item})=>{
  const borderTop=useRef(null);
  const borderBottom=useRef(null);
  const borderLeft=useRef(null);
  const borderRight=useRef(null);
  const showBorder=function(){
      borderTop.current.style.transform="scaleX(1)";
      borderBottom.current.style.transform="scaleX(1)";
      borderLeft.current.style.transform="scaleY(1)";
      borderRight.current.style.transform="scaleY(1)";
  }
  const hideBorder=function(){
      borderTop.current.style.transform="scaleX(0)";
      borderBottom.current.style.transform="scaleX(0)";
      borderLeft.current.style.transform="scaleY(0)";
      borderRight.current.style.transform="scaleY(0)";
  }
  return (
      <div onMouseOver={showBorder} onMouseOut={hideBorder} className="col-10 col-md-5 border border-1 border-solid rounded-2 m-2 position-relative" data-aos="fade-bottom">
          <div ref={borderTop} className="position-absolute w-100 rounded-2 border border-1 border-primary" style={{top:'0px',transition:'.5s',transform:'scaleX(0)',transformOrigin:'left'}}></div>
          <div ref={borderBottom} className="position-absolute w-100 rounded-2 border border-1 border-primary" style={{bottom:'0px',transition:'.5s',transform:'scaleX(0)',transformOrigin:'right'}}></div>
          <div ref={borderLeft} className="position-absolute h-100 rounded-2 border border-1 border-primary" style={{left:'0px',transition:'.5s',transform:'scaleY(0)',transformOrigin:'bottom'}}></div>
          <div ref={borderRight} className="position-absolute h-100 rounded-2 border border-1 border-primary" style={{right:'0px',transition:'.5s',transform:'scaleY(0)',transformOrigin:'top'}}></div>
          <div className="p-1 col-12">
              <div className="row">
                  <div className="col-12 col-md-6">
                    <object data={item._file} className="rounded-2" type="application/pdf" style={{width:'100%'}}/>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="fs-2">{item.name}</div>
                    <div className="d-flex justify-content-center">
                      <a href={item._file} target="_blank">
                        <input type="button" className="btn btn-primary" value="view"/>
                      </a>
                    </div>
                  </div>                      
              </div>
          </div>
      </div>
  )
}
export default ({data})=>{
  useEffect(()=>{
    window.AOS.init({
        offset:0
    });
  },[])
  return (
    <div className='row mt-4' data-aos="fade-left" id="certificate">
      <div className='col-12 text-center'>
        <div className="fs-1">Certificate</div>
        <div className="text-secondary text-center fs-5">little achivement of mine</div>
      </div>

      <div className='col-12 text-center mt-4'>
          <div className="row justify-content-around">
              {
                  data.map((item,index)=>(
                      <Box item={item}/>
                  ))
              }
          </div>
      </div>
    </div>
  )
}