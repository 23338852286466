import { useEffect, useState } from "react"
import Banner from "../../Components/Dashboard/Banner"
import About from "../../Components/Dashboard/About"
import WE from "../../Components/Dashboard/WorkingExperience"
import Portfolio from "../../Components/Dashboard/Portfolio"
import EB from "../../Components/Dashboard/EducationBackground"
import Certificate from "../../Components/Dashboard/Certificate"

import {getAllData} from "../../Services/Home"
import { Oval } from "react-loader-spinner"
import TopBarMenu from "../../Components/Dashboard/TopBarMenu"
export default ()=>{
    const [loading,setLoading]=useState(true);
    
    const [data,setData]=useState({});
    const getData=async()=>{
        setLoading(true);
        const res=await getAllData();
        setData(res.data.data);
        document.title=res.data.data.config[0].name
        setLoading(false);
    }
    useEffect(()=>{        
        window.AOS.init({
            offset:0
        });
        getData();
    },[])
    return (
    <>
        <TopBarMenu/>
        {
            loading
            ?
            <div className="d-flex justify-content-center align-items-center w-100" style={{height:'100vh'}}>
                <Oval width={50} height={50}/>
            </div>
            :
            <div>
                <Banner data={data.config[0]}/>
                <About data={data.config[0]} contact={data.contact}/>
                <div className='row mt-4 p-4 justify-content-between' >
                    <EB data={data.education_background}/>
                    <WE data={data.working_experience}/>
                </div>
                <Portfolio portfolioData={data.portfolio}/>
                <Certificate data={data.certificate}/>
            </div>
        }
    </>
    )
}